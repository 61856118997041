import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface HreflangType {
  code: string;
  url: string;
}

export interface HreflangProps {
  hreflangs: HreflangType[] | null;
}

export const Hreflang = ({ hreflangs }: HreflangProps): JSX.Element | null => {
  if (!hreflangs) {
    return null;
  }

  const hreflangLinks = hreflangs.map((h, index) => (
    // @ts-expect-error Ignore error about capitalization of hrefLang
    <link key={index} rel="alternate" href={h.url} hreflang={h.code} />
  ));

  return <Helmet>{hreflangLinks}</Helmet>;
};
