import _ from 'lodash';
import pathToRegexp from 'path-to-regexp';

import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';
import wwUtility from '../../../ww.utility.ts';

let externalCmsPathsRegex: string[] = [];

export const setExternalCmsPathsRegex = (values: string[]) => {
  externalCmsPathsRegex = [...values];
};

// External Paths following marketBase (eg. /us or /ca/en).
// cf. https://github.com/WW-Digital/parc-fastly-configs/blob/master/prod-www/conditions.json
const externalPathsRegex = [
  '((r|m)(/cms)/)?(find-a-(workshop|studio)|trouvez-un-atelier|finde-einen-workshop|vind-een-workshop|har-finns-vi)(/.*)*',
  '((r|m)(/cms)/)?(find-a-meeting)(/.*)*',
  'live-event-schedule(/.*)*',
  'checkout(/.*)*',
  'shop(/.*)*',
  'gs(/)?',
];

const externalCountryPathsRegex: Record<string, Record<string, string[]>> = {
  au: {
    en: ['en/preferences(/.*)*', 'personal-assessment(/.*)*'],
  },
  be: {
    fr: ['preferences(/.*)*', 'questionnaire(/.*)*'],
    nl: ['preferences(/.*)*', 'persoonlijke-vragenlijst(/.*)*'],
  },
  br: {
    pt: ['pt/preferences(/.*)*', 'avaliacao-pessoal(/.*)*'],
  },
  ca: {
    en: ['preferences(/.*)*', 'personalassessment(/.*)*'],
    fr: ['preferences(/.*)*', 'evaluationpersonnelle(/.*)*'],
  },
  ch: {
    de: ['preferences(/.*)*', 'fragebogen(/.*)*'],
    fr: ['preferences(/.*)*', 'questionnaire(/.*)*'],
  },
  de: {
    de: ['de/preferences(/.*)*', 'fragebogen(/.*)*'],
  },
  fr: {
    fr: ['fr/preferences(/.*)*', 'boutique(/.*)*', 'bilan-minceur-ww(/.*)*'],
  },
  nl: {
    nl: ['nl/preferences(/.*)*', 'persoonlijke-vragenlijst(/.*)*'],
  },
  nz: {
    en: ['en/preferences(/.*)*', 'personal-assessment(/.*)*'],
  },
  se: {
    sv: ['sv/preferences(/.*)*', 'personligt-assessment(/.*)*'],
  },
  uk: {
    en: ['en/preferences(/.*)*', 'personalassessment(/.*)*'],
  },
  us: {
    en: ['en/preferences(/.*)*', 'personalassessment(/.*)*'],
  },
};

export const legacyPatterns = {
  RecipeRoute: `/recipe/:slug/:id`,
  MealRoute: `/meal/:slug/:id`,
};

export const localizedPath = (
  path: string,
  type: string,
  country: string,
  language: string,
) => {
  const localizedType = wwUtility.getMarketField(country, language, type);
  return path.replace(`/${type}/`, `/${localizedType}/`);
};

export const getPatterns = (country: string, language: string) => {
  return {
    RecipeRoute: `/${wwUtility.getMarketField(
      country,
      language,
      'recipe',
    )}/:slug/:id`,
    MealRoute: `/${wwUtility.getMarketField(
      country,
      language,
      'meal',
    )}/:slug/:id`,
  };
};

const getPatternRegex = (country: string, language: string) => {
  const marketInfo = MarketUtility.getMarketInfo(country, language);
  const allPatterns = [
    ...Object.values(getPatterns(country, language)),
    ...Object.values(legacyPatterns),
  ];
  return allPatterns.map((pattern) => {
    return pathToRegexp(`${marketInfo?.marketBasePath}(/r/cms)?${pattern}`);
  });
};

// Before D8 launch, AppPath is limited to homepage and recipe/meal pages
const isAppPathPreD8 = (
  country: string,
  language: string,
  linkPath: string,
) => {
  const patterns = getPatternRegex(country, language);
  const marketInfo = MarketUtility.getMarketInfo(country, language);
  const homepagePaths = [
    marketInfo?.marketBasePath,
    `${marketInfo?.marketBasePath}/`,
  ];

  if (linkPath) {
    return (
      homepagePaths.indexOf(linkPath) !== -1 ||
      patterns.some((pattern) => {
        return pattern.test(linkPath);
      })
    );
  }
  return false;
};

export const getAllExternalPathsRegexs = (
  country: string,
  language: string,
  isTest = false,
) => {
  let paths = [...externalCmsPathsRegex];

  if (isTest) {
    paths = [
      ...externalPathsRegex,
      ...externalCountryPathsRegex[country][language],
    ];
  }

  return _.uniq(paths);
};

const allExternalPathsRegex = (
  country: string,
  language: string,
  isTest = false,
) => {
  const marketInfo = MarketUtility.getMarketInfo(country, language);

  const allRegex = getAllExternalPathsRegexs(country, language, isTest);

  return new RegExp(
    allRegex
      .map((regex) => `(^${marketInfo?.marketBasePath}/${regex}$)`)
      .join('|'),
  );
};

const isExternalPath = (
  country: string,
  language: string,
  path: string,
  isTest = false,
) => {
  return path.match(allExternalPathsRegex(country, language, isTest));
};

export const isAppPath = (
  country: string,
  language: string,
  path: string,
  isTest = false,
) => {
  const preD8 = wwUtility.getMarketField(country, language, 'preD8');

  if (preD8) {
    return isAppPathPreD8(country, language, path);
  }

  const pathOnly = path.split(/[?#]/)[0];

  const marketInfo = MarketUtility.getMarketInfo(country, language);

  // Required marketBasePath with 0 or more /-starting path components.
  const pathRegex = new RegExp(`^(${marketInfo?.marketBasePath})(/.*)*$`);

  const pathMatches = pathOnly.match(pathRegex);

  // If the path does not match our app path structure, then it must not be in the app.
  if (!pathMatches) {
    return false;
  }

  // Path is in the app as long as it is not in external path list.
  return !isExternalPath(country, language, pathOnly, isTest);
};

const endsWith = (subject: string, search: string) => {
  return (
    subject.substring(subject.length - search.length, subject.length) === search
  );
};

export const isAbsoluteURL = (url: string) => {
  return (
    !!url &&
    (url.indexOf('http://') === 0 ||
      url.indexOf('https://') === 0 ||
      url.indexOf('//') === 0 ||
      url.indexOf('#') === 0 ||
      url.indexOf('mailto:') === 0)
  );
};

export const domainRegex = /.*(\/\/[^/]+)/;

export const isAppURL = (
  country: string,
  language: string,
  url: string,
  isTest = false,
) => {
  // If it is an absolute URL, confirm the domain is right.
  if (isAbsoluteURL(url)) {
    const domainMatches = url.match(domainRegex);

    // If we cannot find the domain, it must be an invalid URL.
    if (!domainMatches) {
      return false;
    }

    const domain = domainMatches[1];

    const publicDomain = AppUtility.getDomain(country, true);
    const awsDomain = AppUtility.getDomain(country, false);

    // If the domain is not valid for our app, then it must be external.
    if (!endsWith(publicDomain, domain) && !endsWith(awsDomain, domain)) {
      return false;
    }
  }

  const path = url.replace(domainRegex, '');

  return isAppPath(country, language, path, isTest);
};
