import React, { ReactNode, useContext } from 'react';
import { useQuery } from 'react-apollo';

import type { ConfigContextType } from '../../context/config.context';
import type { ConfigTranslationsType } from './ConfigTranslations/ConfigTranslations';

import { ConfigContext } from '../../context/config.context.ts';
import { Config } from './Config/Config.tsx';
import { ConfigPath } from './ConfigPath/ConfigPath.tsx';
import { ConfigTranslations } from './ConfigTranslations/ConfigTranslations.tsx';
import { ConfigRetina } from './ConfigRetina/ConfigRetina.tsx';
import { AppUtility } from '../Utility/AppUtility.ts';
import { MarketContext } from '../../context/market.context.ts';
import ConfigQuery from './graphql/ConfigQuery.graphql';

export interface ConfigProviderProps {
  children: ReactNode;
  translations?: ConfigTranslationsType;
}

export const ConfigProvider = ({
  children,
  translations,
}: ConfigProviderProps): JSX.Element | null => {
  const { loading, error, data } = useQuery(ConfigQuery);
  const { country, language } = useContext(MarketContext);

  if (error) {
    if (AppUtility.isNetworkError(error)) {
      throw new Error('Config network timeout.');
    }

    return null;
  }

  if (loading || !data) {
    return null;
  }

  const configValue = Config.getValue(data);

  const config: ConfigContextType = {
    config: configValue,
    paths: ConfigPath.getValue(data),
    translations: ConfigTranslations.getValue(
      translations || {},
      country,
      language,
    ),
  };

  return (
    <ConfigContext.Provider value={config}>
      {ConfigRetina.process(data, children)}
    </ConfigContext.Provider>
  );
};
