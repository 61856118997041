import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import * as ApplangaJS from '@ww-digital/applanga-js';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';

import { Meal } from '@ww-digital/web-palette-react/dist/components/Food/Meal/Meal';
import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { FourOhFourRoute } from '../FourOhFourRoute/FourOhFourRoute.tsx';
import { Redirect } from '../../Redirects/Redirect/Redirect.tsx';
import { RouteMetadata } from '../../Metadata/RouteMetadata/RouteMetadata.tsx';
import { AdsContainer } from '../../AdsContainer/AdsContainer.tsx';
import { GTM } from '../../GTM/GTM.tsx';
import { EmailCaptureModalContainer } from '../../Modals/EmailCaptureModalContainer/EmailCaptureModalContainer.tsx';
import { MeteredContentContainer } from '../../Modals/MeteredContentContainer/MeteredContentContainer.tsx';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';
import { FoodSuggestionsContainer } from '../../Regions/FoodSuggestionsContainer/FoodSuggestionsContainer.tsx';
import { EntitlementRedirect } from '../../Redirects/EntitlementRedirect/EntitlementRedirect.tsx';
import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';

import MealQuery from './graphql/MealQuery.graphql';

interface IngredientDataType {
  quantity: string;
  portionName: string;
  itemDetail: {
    ingredientName: string;
  };
  note: string;
  points: string;
}

interface MealParams {
  slug: string;
  id: string;
}

const noImageFood =
  'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1690840354/visitor-site/prod/us/no-image-food.png';

export const MealRoute = (): JSX.Element => {
  const { country } = useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { translations, config } = useContext<ConfigContextType>(ConfigContext);
  const { slug, id } = useParams<MealParams>();
  const match = useRouteMatch();

  const fallback = `${AppUtility.getDomain(country)}${noImageFood}`;

  const { loading, error, data } = useQuery(MealQuery, {
    variables: {
      id,
      includeSuggestions: true,
    },
    errorPolicy: 'all',
  });

  if (error) {
    if (AppUtility.isNetworkError(error)) {
      throw new Error('Meal network timeout.');
    }

    return <Route component={FourOhFourRoute} />;
  }

  if (loading) {
    // Return 'empty' Meal Component so we can display SkeletonIndicators.
    return (
      <Meal
        social={{
          url: '',
          title: '',
          socialLabels: {},
        }}
        foodMasthead={{
          name: '',
        }}
        items={{
          headline: '',
          items: [],
        }}
        note={{
          headline: '',
          text: 'x', // Need a value here for SkeletonIndicator to appear for note.
        }}
        loading
      />
    );
  }

  // Data error or meal is inactive.
  if (!data || !data.meal || !data.meal.isActive) {
    return <Route component={FourOhFourRoute} />;
  }

  const { meal } = data;

  const { facebook, twitter, pinterest, linkedin } =
    config.socialNetworks || {};

  const socialLabels = {
    facebookLabel: translations.GLOBAL_FACEBOOK_LABEL,
    twitterLabel: translations.GLOBAL_TWITTER_LABEL,
    pinterestLabel: translations.GLOBAL_PINTEREST_LABEL,
    linkedinLabel: translations.GLOBAL_LINKEDIN_LABEL,
  };

  // If slug in URL doesn't match slug from meal,
  // redirect to correct URL
  if (slug !== meal.mealUrl) {
    return <Redirect to={`/meal/${meal.mealUrl}/${meal.id}`} />;
  }

  // Ingredient rendering.
  const items = meal.items.map((item: IngredientDataType) => {
    const name =
      !_.isNull(item.itemDetail) && !_.isNull(item.itemDetail.ingredientName)
        ? item.itemDetail.ingredientName
        : '';

    return {
      ingredient: {
        name,
        note: item.note,
        portion: item.portionName,
        quantity: item.quantity,
      },
      points: item.points,
    };
  });

  const pointsValues = { points: meal.points };

  const pointsAriaLabels = {
    valueLabel: translations.POINTS_POINTS_COIN_ARIA_LABEL,
  };

  // Get Food Points Values and Labels
  const pointsData = FoodUtility.getPointsData(pointsValues, pointsAriaLabels);

  // Get Tooltip Values and Labels
  const tooltipData = {
    coinServingText: translations.POINTS_POINTS_COIN_SERVING_TEXT,
    tooltipContent: translations.POINTS_POINTS_TOOLTIP_CONTENT,
    tooltipLinkText: translations.POINTS_POINTS_TOOLTIP_LINK_TEXT,
    tooltipLink: config.points.tooltipLink,
  };

  const mealJsonLD = {
    '@type': 'Recipe',
    name: meal.displayName,
    ...(meal.image.url && {
      image: {
        '@type': 'ImageObject',
        url: meal.image.url,
        name: ApplangaJS.get(translations.FOOD_IMAGE_OF_TEXT || '', {
          title: meal.displayName,
        }),
      },
    }),
    description: meal.description,
    recipeIngredient: meal.items.map((item: IngredientDataType) => {
      const { quantity, portionName } = item;
      const name =
        !_.isNull(item.itemDetail) && !_.isNull(item.itemDetail.ingredientName)
          ? item.itemDetail.ingredientName
          : '';
      return `${quantity} ${portionName} ${name} ${item.note}`;
    }),
    recipeInstructions: meal.note,
  };

  // Use capitalized entitlement to check ads config is enabled. ie enabledGuest.
  const enabledEntitlement = `enabled${
    _.upperFirst(entitlement) as EntitlementContextType['entitlement']
  }` as const;
  const showAds = config.ads[enabledEntitlement];
  // If no ads sidebar expects null.
  const ads = showAds ? (
    <AdsContainer content={FoodUtility.getAdsContentData(meal)} />
  ) : null;

  const metatagTitle = ApplangaJS.get(translations.METATAG_MEAL_TITLE || '', {
    name: meal.displayName,
  });
  const metatagDescription = ApplangaJS.get(
    translations.METATAG_MEAL_DESCRIPTION || '',
    {
      name: meal.displayName,
    },
  );
  const imageAltTag = ApplangaJS.get(translations.FOOD_ALT_TAG || '', {
    recipeMeal: meal.displayName,
  });

  return (
    <>
      <EntitlementRedirect
        food={{
          type: 'meal',
          sourceType: meal.sourceType,
          slug: meal.mealUrl,
          id: meal.id,
        }}
      />
      <GTM key={`gtm-${meal.id}`} category="meal" />
      <RouteMetadata
        title={`${metatagTitle} | ${translations.METATAG_SITE_NAME}`}
        description={metatagDescription}
        og={{ type: 'article' }}
        image={meal.image.url}
      />
      <JSONLD key="jsonLd" content={mealJsonLD} />
      <Meal
        social={{
          media: meal.image.url || fallback,
          url: `${AppUtility.getDomain(country, true, true)}${match.url}`,
          title: meal.displayName,
          socialLabels,
          facebook: facebook.enabled,
          twitter: twitter.enabled,
          pinterest: pinterest.enabled,
          linkedin: linkedin.enabled,
        }}
        foodMasthead={{
          personalPoints: pointsData,
          name: meal.displayName,
          description: meal.description,
          descriptionReadMore: translations.FOOD_DESCRIPTION_READ_MORE,
          descriptionReadLess: translations.FOOD_DESCRIPTION_READ_LESS,
          image: meal.image.url,
          alt: meal.image.altTag || imageAltTag,
          infoLabel: translations.FOOD_INFO_LABEL,
          ...tooltipData,
        }}
        items={{
          headline: translations.FOOD_MEAL_ITEMS_LABEL,
          items,
        }}
        note={{
          headline: translations.FOOD_NOTES_LABEL,
          text: meal.note,
        }}
        sidebar={ads}
      />
      <FoodSuggestionsContainer suggestions={meal.suggestions} />
      <EmailCaptureModalContainer />
      <MeteredContentContainer type="meal" id={meal.id} />
    </>
  );
};
