import {
  AttributeType,
  TemplatesAttributeType,
} from '../../../hooks/useExperiments';
import { SliceContainerProps } from '../../Slices/SliceContainer/SliceContainer';
import { SlicesContainerProps } from '../../Slices/SlicesContainer/SlicesContainer';

import { getAlterPropsByDate } from './getAlterPropsByDate.ts';
import _ from 'lodash';

export const SLICE_ALTER_METHODS = {
  update: 'update',
  replace: 'replace',
  remove: 'remove',
  inject: 'inject',
};

export const alterSlice = (
  alteredSlice: SliceContainerProps['slice'],
  attribute: TemplatesAttributeType,
  configuration: AttributeType['configuration'],
  experimentTextId: string,
  slices?: SlicesContainerProps['slices'],
) => {
  let alterProps = attribute.alterProps;
  // get date-altered props if there are dates
  if (attribute.dates?.length && configuration?.globalCalendar) {
    const dateAlteredProps = getAlterPropsByDate(
      attribute.dates,
      configuration.globalCalendar,
    );
    // if dateAlteredProps is null: date matched, no changes - don't alter the slice props
    // if dateAlteredProps is undefined: no date matched - use the default alterProps
    // if dateAlteredProps is an object: date matched, with changes - alter the slice props with dateAlteredProps
    if (dateAlteredProps === null) {
      return;
    } else if (dateAlteredProps) {
      alterProps = dateAlteredProps;
    }
  }
  // alter the slice props based on the alterMethod
  switch (attribute.alterMethod) {
    case SLICE_ALTER_METHODS.update:
      for (const propPath in alterProps) {
        _.set(alteredSlice, propPath, alterProps[propPath]);
      }
      alteredSlice.xsExperimentTextId = experimentTextId;
      break;
    case SLICE_ALTER_METHODS.replace:
      slices && slices.splice(slices.indexOf(alteredSlice), 1, alterProps);
      alteredSlice.xsExperimentTextId = experimentTextId;
      break;
    case SLICE_ALTER_METHODS.remove:
      slices && slices.splice(slices.indexOf(alteredSlice), 1);
      alteredSlice.xsExperimentTextId = experimentTextId;
      break;
    case SLICE_ALTER_METHODS.inject:
      slices &&
        typeof attribute.injectionIndex === 'number' &&
        slices.splice(
          slices.indexOf(alteredSlice) + attribute.injectionIndex,
          0,
          alterProps,
        );
      alteredSlice.xsExperimentTextId = experimentTextId;
      break;
  }
};
