import {
  AttributeType,
  TemplatesAttributeType,
} from '../../../hooks/useExperiments';
import { SlicesContainerProps } from '../../Slices/SlicesContainer/SlicesContainer';
import { SliceContainerProps } from '../../Slices/SliceContainer/SliceContainer';

import { alterSlice } from './alterSlice.ts';

export const getAlteredSlices = (
  slices: SlicesContainerProps['slices'],
  attributes: AttributeType['attributes'],
  configuration: AttributeType['configuration'],
  experimentTextId: string,
  region?: string,
): SlicesContainerProps['slices'] => {
  for (const attributeName in attributes) {
    const attribute: TemplatesAttributeType = attributes[attributeName];
    // skip attribute if it doesn't alter slices based on templates (doesn't have a config/ not TemplatesAttributeType)
    if (!attribute.config) {
      continue;
    }
    // skip attribute if it's not in the region
    if (attribute.config.region && attribute.config.region !== region) {
      continue;
    }

    const attributeSliceSelector = attribute.config.sliceSelector.targetBy
      ? attribute.config.sliceSelector.targetBy
      : 'id';

    const alteredSlice = slices.find(
      (slice: SliceContainerProps['slice']) =>
        slice[attributeSliceSelector] ===
        attribute.config.sliceSelector[attributeSliceSelector],
    );

    if (alteredSlice) {
      alterSlice(
        alteredSlice,
        attribute,
        configuration,
        experimentTextId,
        slices,
      );
    }
  }
  return slices;
};
