import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';

import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';

// @TODO Join JSONLD Types
interface JSONLDProps {
  content?: any;
  nestedKey?: string;
}

export const JSONLD = ({
  content,
  nestedKey = 'publisher',
}: JSONLDProps): JSX.Element => {
  const { config } = useContext<ConfigContextType>(ConfigContext);
  const { country } = useContext<MarketContextType>(MarketContext);

  const getPublisherName = () => {
    switch (country) {
      case 'se':
        return 'ViktVäktarna';
      case 'br':
        return 'Vigilantes do Peso';
      default:
        return 'WeightWatchers';
    }
  };

  const publisher = {
    '@type': 'Organization',
    name: getPublisherName(),
    logo: {
      '@type': 'ImageObject',
      URL: config.jsonLD.logo,
    },
    URL: AppUtility.getBaseUrl(country),
  };

  const ldJson = {
    '@context': 'https://schema.org/',
    [nestedKey]: { ...publisher },
    ...content,
  };

  const name = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: getPublisherName(),
    url: AppUtility.getBaseUrl(country),
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(name)}</script>
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  );
};
