import React, { useEffect, useContext } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import type { MarketContextType } from '../context/market.context';
import type { ConfigContextType } from '../context/config.context';

import { ContentRoute } from './Route/ContentRoute/ContentRoute.tsx';
import { CoachBrowserRoute } from './Route/CoachBrowserRoute/CoachBrowserRoute.tsx';
import { FourOhFourRoute } from './Route/FourOhFourRoute/FourOhFourRoute.tsx';
import { RecipeRoute } from './Route/RecipeRoute/RecipeRoute.tsx';
import { MealRoute } from './Route/MealRoute/MealRoute.tsx';
import { DemoRoute } from './Route/Demo/DemoRoute.tsx';
import { InternationalPageRoute } from './Route/InternationalPageRoute/InternationalPageRoute.tsx';
import { Counter } from './Utility/Counter.ts';
import { AppUtility } from './Utility/AppUtility.ts';
import { MarketContext } from '../context/market.context.ts';
import { ConfigContext } from '../context/config.context.ts';
import { MarketUtility } from './Utility/MarketUtility.ts';
import { CancellationPageRoute } from './Route/CancellationPageRoute/CancellationPageRoute.tsx';

export const Routes = () => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const {
    paths: {
      getAllExternalPathsRegexs,
      legacyPatterns,
      localizedPath,
      getPatterns,
    },
    config: {
      contributor: { enabledContributors },
    },
  } = useContext<ConfigContextType>(ConfigContext);

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    const historyListenHandler = history.listen(() => {
      // The route has changed.
      Counter.resetCounter();
    });

    return () => {
      historyListenHandler();
    };
  }, [history]);

  const env = AppUtility.getEnv();
  const patterns = getPatterns(country, language);
  const coachBrowserPath = MarketUtility.getCoachPath(country, language);
  const isGermanMarket =
    MarketUtility.getCountryInfo(country)?.country === 'de';

  return (
    <div id="main" data-e2e-name="main">
      <Switch>
        {/* Demo Routes */}
        {env !== 'prod' && (
          <Route path={`${match.path}/demo`} component={DemoRoute} />
        )}

        {/* Virtual Coach Route (before external paths) */}
        {coachBrowserPath && (
          <Route
            exact
            path={`${match.path}${coachBrowserPath}`}
            component={CoachBrowserRoute}
          />
        )}

        {/* 404 for external paths */}
        {getAllExternalPathsRegexs(country, language).map(
          (path: string, index: number) => (
            <Route
              path={`${match.path}${path}`}
              component={FourOhFourRoute}
              key={index}
            />
          ),
        )}

        {/* Food Routes */}
        <Route
          exact
          path={`${match.path}${patterns.RecipeRoute}`}
          component={RecipeRoute}
        />
        <Route
          exact
          path={`${match.path}${patterns.MealRoute}`}
          component={MealRoute}
        />

        {/* Legacy Food Routes, after Food Routes */}
        <Redirect
          exact
          from={`${match.path}${legacyPatterns.RecipeRoute}`}
          to={localizedPath(location.pathname, 'recipe', country, language)}
        />
        <Redirect
          exact
          from={`${match.path}${legacyPatterns.MealRoute}`}
          to={localizedPath(location.pathname, 'meal', country, language)}
        />

        {/* International Page Route */}
        <Route
          exact
          path={`${match.path}/international`}
          component={InternationalPageRoute}
        />

        {/* German Market Cancellation Form */}
        {isGermanMarket && (
          <Route
            path={`${match.path}/kuendigungsantrag`}
            component={CancellationPageRoute}
          />
        )}

        {/* Contributor Page Routes */}
        {enabledContributors &&
          ['author', 'reviewer'].map((type: string) => (
            <Route
              exact
              path={`${match.path}${MarketUtility.getContributorPath(
                type,
                country,
                language,
              )}/:contributorSlug`}
              key={type}
            >
              <ContentRoute isContributorRoute={true} contributorType={type} />
            </Route>
          ))}

        {/* Assume all other routes are pages in Drupal and handle them with the ContentRoute component */}
        <Route path={`${match.path}/:path(.*)`} component={ContentRoute} />

        {/* Special case for the homepage with no / */}
        <Route path={match.path} component={ContentRoute} />

        {/* Always should be last in the list as it is the catch all. */}
        <Route component={FourOhFourRoute} />
      </Switch>
    </div>
  );
};
