import React, { ReactNode } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation, useRouteMatch } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

import {
  SectionContext,
  defaultSectionContext,
} from '../../../context/section.context.ts';

import SectionsQuery from './graphql/SectionsQuery.graphql';

interface SectionProviderProps {
  children: ReactNode;
}

interface SectionProviderPaths {
  paths: string;
  machineName: string;
}

export const SectionProvider = ({
  children,
}: SectionProviderProps): JSX.Element | null => {
  const match = useRouteMatch();
  const location = useLocation();

  // Calculate the score for a path pattern to see how much weight it should give to being selected.
  const getPathPatternScore = (pathPatternParts: string[]) => {
    return pathPatternParts.reduce((score, pathPatternPart, index) => {
      // If the pattern is a wildcard, give it less score.
      const weight = pathPatternPart === '*' ? 1 : 2;

      return score * (index + 1) * weight;
    }, 1);
  };

  // Checks a path against a list of path patterns to see how well it matches.
  const getSectionMatchScore = (path: string, pathPatterns: string) => {
    let positiveMatchScore = 0;
    let negativeMatchScore = 0;

    const pathParts = path.toLowerCase().split('/');

    for (let i = 0; i < pathPatterns.length; i++) {
      const pathPattern = pathPatterns[i].toLowerCase();

      const isNot = pathPattern.indexOf('~') === 0;

      const pathPatternParts = pathPattern.substring(isNot ? 1 : 0).split('/');

      const isMatch = pathPatternParts.every((pathPatternPart, index) => {
        // If we don't have a matching part in the path, then it does not match.
        if (index >= pathParts.length) {
          return false;
        }

        // If the pattern is a wildcard, always match, but give it less weight.
        if (pathPatternPart === '*') {
          return true;
        } else if (pathPatternPart === pathParts[index]) {
          // If this is the last part of the pattern, but the path has more parts, then this isn't a match even though this last part of the pattern matched.
          if (
            index === pathPatternParts.length - 1 &&
            pathParts.length > pathPatternParts.length
          ) {
            return false;
          }

          // If we have an exact path match, give it double the weight of a wildcard.
          return true;
        }

        return false;
      });

      // If this is a negation, then do some special handling based on whether or not it matched.
      if (isNot) {
        if (isMatch) {
          return 0;
        }

        // Limit the relative value of negations to just 1 point per negative match.
        negativeMatchScore = negativeMatchScore + 1;
      } else if (isMatch) {
        positiveMatchScore =
          positiveMatchScore + getPathPatternScore(pathPatternParts);
      }
    }

    // We need at least one positive match for it to be considered a real match. You can't just say what it is not.
    if (positiveMatchScore > 0) {
      return positiveMatchScore + negativeMatchScore;
    }

    return 0;
  };

  // Get the best section based on the path.
  const getSection = (path: string, sections: SectionProviderPaths[]) => {
    const homepageSectionMachineName = 'homepage_only';
    // Homepage?
    if (!path) {
      const homepageSection = sections.filter(
        (section) => section.machineName === homepageSectionMachineName,
      );
      if (homepageSection && homepageSection.length > 0) {
        return homepageSectionMachineName;
      }
    }

    let matchScore = 0;
    let matchSection: SectionProviderPaths | undefined;

    // Check each section to see how well it matches the path.
    sections.forEach((section) => {
      if (section.machineName !== homepageSectionMachineName) {
        const sectionMatchScore = getSectionMatchScore(path, section.paths);

        // Pick out the best matched section.
        if (sectionMatchScore > matchScore) {
          matchScore = sectionMatchScore;
          matchSection = section;
        }
      }
    });

    // Return the best section. Returning null is fine as having no section might be reasonable in
    // some fringe cases.
    return matchSection && matchSection['machineName'];
  };

  // Get the relative URL path by stripping off the country and entitlements.
  const getRelativePath = () => {
    const pathRegex = pathToRegexp(match.path, [], { end: false });

    return location.pathname.replace(pathRegex, '').replace(/^\/+/, '');
  };

  const { loading, error, data } = useQuery(SectionsQuery);

  if (loading || error || !data) {
    return null;
  }

  const relativePath = getRelativePath();

  const section =
    getSection(relativePath, data.sections) || defaultSectionContext;

  return (
    <SectionContext.Provider value={section}>
      {children}
    </SectionContext.Provider>
  );
};
