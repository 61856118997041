const wwConfig = require('./ww.config.ts');

function isBrowser(): boolean {
  return process && process.env && process.env.NODE_ENV === 'test'
    ? false
    : typeof window !== 'undefined';
}

function getEnv(): string {
  return isBrowser()
    ? window.environment || ''
    : process.env.ENVIRONMENT_NAME || '';
}

// Get the domain to hit for other API requests.
function getAPIDomain(): string {
  return isBrowser() ? window.api_url || '' : process.env.API_URL || '';
}

// Get the domain to hit for GQL requests.
function getGQLDomain(): string {
  return isBrowser() ? window.gql_url || '' : process.env.GQL_URL || '';
}

// Get the prod GQL domain.
function getProdGQLDomain(): string {
  return isBrowser()
    ? window.gql_url_prod || ''
    : process.env.GQL_URL_PROD || '';
}

// Get the URL to hit for GQL requests.
function getGQLUri(
  country: string,
  language: string,
  domainOverride?: string,
): string {
  const domain = !!domainOverride ? domainOverride : getGQLDomain();
  return `${domain}/v1/guest/gql?market=${language}-${country}`;
}

function getVSDomain(): string {
  return isBrowser() ? window.vs_url || '' : process.env.URL || '';
}

// Get a configuration field for specified market.
function getMarketField(country: string, language: string, field: string) {
  return wwConfig.markets[country] &&
    wwConfig.markets[country][language] &&
    wwConfig.markets[country][language][field]
    ? wwConfig.markets[country][language][field]
    : null;
}

// Common functions for WW operations.
/* eslint-disable import/no-anonymous-default-export */
export default {
  isBrowser,
  getEnv,
  getAPIDomain,
  getProdGQLDomain,
  getGQLDomain,
  getGQLUri,
  getVSDomain,
  getMarketField,
};
