import _ from 'lodash';

interface DomainConfig {
  protocol?: string;
  subdomain?: string;
  environmentMap?: Record<string, string>;
  domainMap?: Record<string, Record<string, string>>;
  domain?: string;
  localDomain?: string;
}

// A helper class for building a domain based on environment, country, language, and other info.
export class Domain {
  protocol;
  subdomain;
  environmentMap;
  domainMap;
  domain;
  localDomain;

  constructor(config: DomainConfig) {
    const {
      protocol,
      subdomain,
      environmentMap,
      domainMap,
      domain,
      localDomain,
    } = config;

    this.protocol = protocol || Domain.protocol;
    this.subdomain = subdomain || Domain.subdomain;
    this.environmentMap = _.merge({}, Domain.environmentMap, environmentMap);
    this.domainMap = _.merge({}, Domain.domainMap, domainMap);
    this.domain = domain || Domain.domain;
    this.localDomain = localDomain || Domain.localDomain;
  }

  static protocol = 'https';

  static subdomain = false;

  static domain = false;

  static localDomain = false;

  static environmentMap = {
    local: 'qat2.',
    dev: 'qat2.',
    stg: 'qat2.',
    stg2: 'qat2.',
    stg3: 'qat2.',
    prod: '',
  };

  static domainMap: Record<string, { [key: string]: string }> = {
    au: {
      en: 'weightwatchers.com.au',
    },
    be: {
      nl: 'weightwatchers.be',
      fr: 'fr.weightwatchers.be',
    },
    br: {
      pt: 'vigilantesdopeso.com.br',
    },
    ca: {
      en: 'weightwatchers.ca',
      fr: 'fr.weightwatchers.ca',
    },
    ch: {
      de: 'weightwatchers.ch',
      fr: 'fr.weightwatchers.ch',
    },
    de: {
      de: 'weightwatchers.de',
    },
    fr: {
      fr: 'weightwatchers.fr',
    },
    nl: {
      nl: 'weightwatchers.nl',
    },
    nz: {
      en: 'weightwatchers.com.au',
    },
    se: {
      sv: 'viktvaktarna.se',
    },
    uk: {
      en: 'weightwatchers.co.uk',
    },
    us: {
      en: 'weightwatchers.com',
    },
  };

  // Get the environment subdomain for the API endpoint.
  getSubdomain(environment: string) {
    const envSubdomain = _.get(
      this.environmentMap,
      environment,
      this.environmentMap.dev,
    );
    const apiSubdomain = this.subdomain ? `${this.subdomain}.` : '';

    return `${apiSubdomain}${envSubdomain}`;
  }

  // Get the base domain for the API endpoint.
  getDomain(environment: string, country: string, language: string) {
    // If local return local URL.
    if (environment === 'local' && this.localDomain) return this.localDomain;

    // If we have a standard domain set return it.
    if (this.domain) return this.domain;

    return _.get(
      this.domainMap,
      `${country}.${language}`,
      this.domainMap.us.en,
    );
  }

  // Get the API endpoint.
  getEndpoint(environment: string, country: string, language: string) {
    const subdomain = this.getSubdomain(environment);
    const domain = this.getDomain(environment, country, language);

    return `${this.protocol}://${subdomain}${domain}`;
  }
}
