import React, { useContext, useRef } from 'react';
import { useQuery } from 'react-apollo';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';

import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { Counter } from '../../Utility/Counter.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

import {
  CardGridSlice,
  CardGridSliceProps,
} from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import Colors from '@ww-digital/web-palette-react/dist/components/Color/Colors';

import ArticleSuggestionsQuery from './graphql/ArticleSuggestionsQuery.graphql';

interface ArticleSuggestionsContainerProps {
  path?: string;
  topLevelCategory?: string;
}

interface ArticleSuggestionsLatestContent {
  id: string;
  title: string;
  link: string;
  image?: string;
}

export const ArticleSuggestionsContainer = ({
  path,
  topLevelCategory = '',
}: ArticleSuggestionsContainerProps): JSX.Element | null => {
  const { marketBasePath } = useContext<MarketContextType>(MarketContext);
  const { entitlement, entitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { config, translations } = useContext<ConfigContextType>(ConfigContext);
  const sliceIndex = useRef<number>(
    Counter.getCounter('card-grids-slice-number'),
  );

  const processLatestContent = (
    latestContent: ArticleSuggestionsLatestContent[],
  ) => {
    if (latestContent.length === 0) {
      return latestContent;
    }

    // Check if current path maches with the exclude path.
    if (isExcludePath(config.latestContent.pathsExclude)) {
      return [];
    }

    // Discard a card if it is current content otherwise discard the last one.
    for (let i = 0; i < latestContent.length; i++) {
      if (path === _.get(latestContent[i], 'link', '').substring(1)) {
        latestContent.splice(i, 1);
        return latestContent;
      }
    }

    latestContent.pop();

    return latestContent;
  };

  const isExcludePath = (pathsExclude: string[]) => {
    const exclude: string[] = [];
    const include: string[] = [];
    // Go through each item and check with the current path if it needs to be excluded.
    pathsExclude.forEach((element) => {
      if (!!element) {
        if (element.charAt(0) === '~') {
          include.push(element.substring(1));
        } else {
          exclude.push(element);
        }
      }
    });

    for (let i = 0; i < include.length; i++) {
      const regex = RegExp('^' + include[i].replace('*', '.*') + '$');

      if (regex.test(path || '')) {
        return false;
      }
    }
    for (let i = 0; i < exclude.length; i++) {
      const regex = RegExp('^' + exclude[i].replace('*', '.*') + '$');

      if (regex.test(path || '')) {
        return true;
      }
    }

    return false;
  };

  const { loading, error, data } = useQuery(ArticleSuggestionsQuery, {
    errorPolicy: 'all',
  });

  if (error || loading || !data.latestContent) {
    return null;
  }

  const latestContent = processLatestContent(_.cloneDeep(data.latestContent));

  const recircSlice: CardGridSliceProps = {
    align: 'left',
    heading: {
      text: translations.ARTICLE_RECIRC_HEADING_TEXT,
      typog: 'headline4',
      variant: 'default',
      ariaLevel: 2,
    },
    background: {
      color: Colors.colorWhite,
    },
    cardList: [],
  };

  recircSlice.cardList = latestContent.map((item, index) => {
    const title = _.get(item, 'title', '');

    const cardActionAttributes = AnalyticsUtility.formatCardAnalytics({
      type: AnalyticsUtility.cardTypes.TYPE_CONTENT_CARD_GRID_RECOMMENDATION,
      pageIndex: 1, // always 1 as there is no pager for this type of slice.
      sliceIndex: sliceIndex.current, // card grid slice index on the page
      cardIndex: ++index, // card index in slice
      topLevelCategory,
      cardId: item.id,
      cardTitle: title,
      entitlement,
    });

    return {
      image: _.get(item, 'image', ''),
      link: `${marketBasePath}${entitlementBasePath}${_.get(item, 'link', '')}`,
      title,
      cardActionAttributes,
    };
  });

  return recircSlice.cardList.length ? (
    <CardGridSlice
      type="content"
      cardList={recircSlice.cardList}
      heading={recircSlice.heading}
      background={recircSlice.background}
    />
  ) : null;
};
