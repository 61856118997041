import React, { ReactNode, useContext } from 'react';
import { XSProvider } from '@ww-digital/xs-sdk-react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import GUA from '@ww-digital/xs-plugin-gua';
import _ from 'lodash';
import deepmerge from 'deepmerge';
import { QueryStringUtility } from '../../Utility/QueryStringUtility.ts';

import type { NormalizedExperiments } from '@ww-digital/xs-sdk';
import type { MarketContextType } from '../../../context/market.context';
import type { TestItemType, VariationItemType } from '../../../ww.tests';
import type { ExperimentContextType } from '../../../context/experiment.context';

import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { getExperimentsPreviewHeader } from '../../Utility/xsUtility/xsPreview.tsx';
import { isDisableXS } from '../../Utility/xsUtility/xsDisable.tsx';
import { MarketContext } from '../../../context/market.context.ts';
import { wwTests } from '../../../ww.tests.ts';
import { ExperimentContext } from '../../../context/experiment.context.ts';
import wwUtility from '../../../ww.utility.ts';

import ExperimentsQuery from './graphql/ExperimentsQuery.graphql';
import { AppProps } from '../../App';
import { filterExperiments } from '../../Utility/xsUtility/filterExperiments.ts';
import { v4 as uuidv4 } from 'uuid';

export interface AppXSProviderProps {
  children: ReactNode;
  ssr: boolean;
  experiments?: ExperimentContextType['experiments'];
  experimentsHeader?: string;
  experimentsFranchise?: string;
  url: AppProps['url'];
  cookies?: string;
}

const gua = new GUA();

export const testRegularExpression = (
  regexPath: string,
  pagePathname: string,
) => {
  const testStringForRegex: boolean =
    regexPath.startsWith('^') && regexPath.endsWith('$');
  const regex = new RegExp(regexPath);

  return testStringForRegex && regex.test(pagePathname);
};

export const testRegexForAudienceCookies = (
  regexData: string,
  searchParam: string,
) => {
  const regex = new RegExp(regexData);
  return regex.test(searchParam);
};

let sessionIDChecks = 0;

export const setSessionID = () => {
  if (wwUtility.isBrowser()) {
    if (document.cookie?.includes('ww_browser_id=')) {
      const browserID = document.cookie
        .split('ww_browser_id=')[1]
        .split(';')[0];
      if (!sessionStorage.getItem('sessionID')) {
        const sessionID = uuidv4();
        sessionStorage.setItem('sessionID', `${sessionID}:${browserID}`);
        return;
      } else {
        if (browserID !== sessionStorage.getItem('sessionID')?.split(':')[1]) {
          console.warn('XS: SessionID mismatch for BrowserID...');
          return;
        }
      }
    } else {
      if (sessionIDChecks <= 50) {
        setTimeout(function () {
          sessionIDChecks++;
          setSessionID();
        }, 100);
      } else {
        return;
      }
    }
  } else {
    return;
  }
};

export const AppXSProvider = ({
  children,
  ssr,
  experiments: experimentsInit,
  experimentsHeader,
  experimentsFranchise,
  url,
  cookies,
}: AppXSProviderProps): JSX.Element => {
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { loading, error, data } = useQuery(ExperimentsQuery);
  const { pathname, search: searchParam } = useLocation();
  const experiments = experimentsInit || {};
  const epochStartGmt = new Date(1000).toUTCString();
  let mergedTests = wwTests;
  let experimentsPreviewHeader = '';
  let contextExperimentsHeader = '';
  const queryString = QueryStringUtility.getQueryParams(url.search);
  setSessionID();

  // Check whether test is in ww.tests.ts file
  if (experiments && data?.xsExperiments && !loading && !error) {
    const normalizedExperiments: NormalizedExperiments =
      data.xsExperiments.experiments;

    const normExKeys = Object.keys(normalizedExperiments);

    let updatableWWTests = _.cloneDeep(wwTests);

    const validKey = normExKeys.filter((element) =>
      !queryString.exptextid &&
      !queryString.varid &&
      !queryString.xspreviewtests &&
      !cookies?.includes('xsPreviewCookie')
        ? element.includes('XS-Template') &&
          normalizedExperiments[element]?.status === 2
        : element.includes('XS-Template'),
    );

    const xsAudienceCookies = Storage.getCookieValue(
      'xsAudienceCookies',
      false,
    );

    const xsAudienceCookiesTemp = Storage.getCookieValue(
      'xsAudienceCookiesTemp',
      false,
    );

    const xsAudienceExclusionCookies = Storage.getCookieValue(
      'xsAudienceExclusionCookies',
      false,
    );

    const xsAudienceExclusionCookiesTemp = Storage.getCookieValue(
      'xsAudienceExclusionCookiesTemp',
      false,
    );

    // Combine Test Auto-Routes
    validKey.forEach((key) => {
      let enterTest = false;
      let dontEnterTest = false;

      // Check against Audience Conditions for entry
      if (
        normalizedExperiments[
          key
        ]?.variations?.control?.attributes.hasOwnProperty('audience')
      ) {
        try {
          const testData = JSON.parse(
            `${normalizedExperiments[key].variations.control.attributes['audience'].attributeValue}`,
          );

          const experimentTextId = normalizedExperiments[key].experimentTextId;

          // Older version of Cookie Audience Settings, keeping this so older tests can still run...
          if (testData?.queryParam) {
            enterTest = testRegexForAudienceCookies(
              testData?.queryParam,
              searchParam,
            );

            //  Add test to xsAudienceCookie if we're in browser, we have query params present, and the item isn't already in the xsAudienceCookie list
            if (
              wwUtility.isBrowser() &&
              enterTest &&
              typeof xsAudienceCookies === 'string' &&
              !xsAudienceCookies.includes(experimentTextId)
            ) {
              const xsCookiesListGeneration =
                xsAudienceCookies + '.' + experimentTextId;
              document.cookie = `xsAudienceCookies=${xsCookiesListGeneration};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
            }

            if (
              wwUtility.isBrowser() &&
              enterTest &&
              xsAudienceCookies === null
            ) {
              document.cookie = `xsAudienceCookies=${experimentTextId};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
            }
          }

          // New Audience Inclusion/Exclusion Code
          if (
            !!testData?.inclusions?.queryParam ||
            !!testData?.exclusions?.queryParam
          ) {
            if (
              testData?.inclusions?.queryParam &&
              testRegexForAudienceCookies(
                testData?.inclusions?.queryParam,
                searchParam,
              )
            ) {
              enterTest = true;
            }

            if (
              testData?.exclusions?.queryParam &&
              testRegexForAudienceCookies(
                testData?.exclusions?.queryParam,
                searchParam,
              )
            ) {
              dontEnterTest = true;
            }

            //  Add test to xsAudienceCookie if we're in browser, we have query params present, and the item isn't already in the xsAudienceCookie list
            if (wwUtility.isBrowser() && (enterTest || dontEnterTest)) {
              // Set Cookies if Cookie groups already exist
              if (
                testData?.inclusions?.duration === 'fullTest' &&
                typeof xsAudienceCookies === 'string' &&
                !xsAudienceCookies.includes(experimentTextId)
              ) {
                const xsCookiesListGeneration =
                  xsAudienceCookies + '.' + experimentTextId;
                document.cookie = `xsAudienceCookies=${xsCookiesListGeneration};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
              }

              if (
                testData?.inclusions?.duration === 'session' &&
                typeof xsAudienceCookiesTemp === 'string' &&
                !xsAudienceCookiesTemp.includes(experimentTextId)
              ) {
                const xsCookiesListGeneration =
                  xsAudienceCookiesTemp + '.' + experimentTextId;
                document.cookie = `xsAudienceCookiesTemp=${xsCookiesListGeneration};path=/;`;
              }

              if (
                testData?.exclusions?.duration === 'fullTest' &&
                typeof xsAudienceExclusionCookies === 'string' &&
                !xsAudienceExclusionCookies.includes(experimentTextId)
              ) {
                const xsCookiesListGeneration =
                  xsAudienceExclusionCookies + '.' + experimentTextId;
                document.cookie = `xsAudienceExclusionCookies=${xsCookiesListGeneration};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
              }

              if (
                testData?.exclusions?.duration === 'session' &&
                typeof xsAudienceExclusionCookiesTemp === 'string' &&
                !xsAudienceExclusionCookiesTemp.includes(experimentTextId)
              ) {
                const xsCookiesListGeneration =
                  xsAudienceExclusionCookiesTemp + '.' + experimentTextId;
                document.cookie = `xsAudienceExclusionCookiesTemp=${xsCookiesListGeneration};path=/;`;
              }

              // Set Cookies if Cookie groups don't yet exist
              if (
                xsAudienceCookies === null &&
                testData?.inclusions?.duration === 'fullTest' &&
                testRegexForAudienceCookies(
                  testData?.inclusions?.queryParam,
                  searchParam,
                )
              ) {
                document.cookie = `xsAudienceCookies=${experimentTextId};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
              }
              if (
                xsAudienceCookiesTemp === null &&
                testData?.inclusions?.duration === 'session' &&
                testRegexForAudienceCookies(
                  testData?.inclusions?.queryParam,
                  searchParam,
                )
              ) {
                document.cookie = `xsAudienceCookiesTemp=${experimentTextId};path=/;`;
              }
              if (
                xsAudienceExclusionCookies === null &&
                testData?.exclusions?.duration === 'fullTest' &&
                testRegexForAudienceCookies(
                  testData?.exclusions?.queryParam,
                  searchParam,
                )
              ) {
                document.cookie = `xsAudienceExclusionCookies=${experimentTextId};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
                enterTest = false;
              }
              if (
                xsAudienceExclusionCookiesTemp === null &&
                testData?.exclusions?.duration === 'session' &&
                testRegexForAudienceCookies(
                  testData?.exclusions?.queryParam,
                  searchParam,
                )
              ) {
                document.cookie = `xsAudienceExclusionCookiesTemp=${experimentTextId};path=/;`;
                enterTest = false;
              }
            }
          }

          // Sets Test Entrance to True if Inclusion Cookie Exists
          if (
            cookies &&
            (new RegExp(
              '^(?=.*xsAudienceCookies=)(?=.*' + experimentTextId + '(.|;)).*$',
            ).test(cookies) ||
              new RegExp(
                '^(?=.*xsAudienceCookiesTemp=)(?=.*' +
                  experimentTextId +
                  '(.|;)).*$',
              ).test(cookies) ||
              (testData?.inclusions?.duration === 'noCookie' &&
                testRegexForAudienceCookies(
                  testData?.inclusions?.queryParam,
                  searchParam,
                )))
          ) {
            enterTest = true;
          }

          // Sets Test Entrance to False if Exclusion Cookie Exists
          if (
            cookies &&
            (new RegExp(
              '^(?=.*xsAudienceExclusionCookies=)(?=.*' +
                experimentTextId +
                '(.|;)).*$',
            ).test(cookies) ||
              new RegExp(
                '^(?=.*xsAudienceExclusionCookiesTemp=)(?=.*' +
                  experimentTextId +
                  '(.|;)).*$',
              ).test(cookies) ||
              (testData?.exclusions?.duration === 'noCookie' &&
                testRegexForAudienceCookies(
                  testData?.exclusions?.queryParam,
                  searchParam,
                )))
          ) {
            dontEnterTest = true;
            enterTest = false;
          }

          // Allows for audience bucketing if only an exclusion is set. Anyone not within the exclusion is entered into the test.
          if (
            testData?.exclusions?.allExceptExclusion === true &&
            !testRegexForAudienceCookies(
              testData?.exclusions?.queryParam,
              searchParam,
            )
          ) {
            enterTest = true;
          }
        } catch (ex) {
          console.warn(ex);
        }
      }

      try {
        if (
          dontEnterTest !== true &&
          normalizedExperiments[key]?.variations?.control?.attributes
            ?.autoRoute &&
          (!normalizedExperiments[
            key
          ]?.variations?.control?.attributes.hasOwnProperty('audience') ||
            enterTest)
        ) {
          updatableWWTests = deepmerge(
            updatableWWTests,
            JSON.parse(
              normalizedExperiments[key]?.variations?.control?.attributes
                ?.autoRoute.attributeValue ?? null,
            ),
          );
        }
      } catch (ex) {
        console.warn(ex);
      }
    });

    // Clean Up Test Auto-Routes
    const uniquePaths: string[] = [];
    const arrayRebuild: { path: string; tests?: VariationItemType[] }[] = [];

    updatableWWTests[country][language].forEach(
      (items: { path: string; tests?: VariationItemType[] }) => {
        if (!uniquePaths.includes(items.path)) {
          uniquePaths.push(items.path);
          arrayRebuild.push(items);
        } else {
          arrayRebuild.forEach(
            (subItems: { path: string; tests?: VariationItemType[] }) => {
              if (subItems.path === items.path && items.tests?.[0]) {
                subItems.tests?.push(items.tests[0]);
              }
            },
          );
        }
      },
    );

    updatableWWTests[country][language] = _.cloneDeep(arrayRebuild);
    mergedTests = _.merge({}, wwTests, updatableWWTests);

    // Add changes for REGEX match...
    const testObj = (mergedTests[country][language] || []).filter(
      (test: TestItemType) =>
        pathname &&
        (test.path === pathname ||
          testRegularExpression(test.path, pathname)) &&
        test.tests,
    );

    const filteredObj: VariationItemType[][] = [];
    let filteredObjFlat: VariationItemType[] = [];

    testObj.forEach((test: TestItemType) => {
      if (test.tests !== undefined) {
        filteredObj.push(test.tests);
      }
    });

    filteredObjFlat = filteredObj.flat(1);

    filteredObjFlat?.forEach((test: VariationItemType) => {
      const excludeNACO = test.excludeNACO && experimentsFranchise !== 'Y';
      const excludeFranchise =
        test.excludeFranchise && experimentsFranchise === 'Y';
      if (
        test.xs &&
        normalizedExperiments[test.xs] &&
        !excludeFranchise &&
        !excludeNACO
      ) {
        experiments[test.xs] = normalizedExperiments[test.xs];
      }
    });

    // Filter out tests from xsAudienceCookies Inclusions if test no longer active
    if (typeof xsAudienceCookies === 'string') {
      const xsAudienceCookiesArray = xsAudienceCookies.split('.');
      const xsAudienceCookieSplice = _.clone(xsAudienceCookiesArray);

      xsAudienceCookiesArray?.forEach((key: string) => {
        // Removes "--##" substring from checked keys (if substring exists) to retain cookies for content-based query check
        if (!validKey.includes(key.replace(/(--([0-9]+))/g, ''))) {
          const xsAudienceCookieIndex = xsAudienceCookieSplice.indexOf(key);
          xsAudienceCookieSplice.splice(xsAudienceCookieIndex, 1);
          if (xsAudienceCookieSplice.length === 0) {
            document.cookie = `xsAudienceCookies=;expires=${epochStartGmt};`;
            document.cookie = `xsAudienceCookies=;expires=${epochStartGmt};path=/;`;
          } else {
            document.cookie = `xsAudienceCookies=${xsAudienceCookieSplice
              .toString()
              .replace(',', '.')};path=/;`;
          }
        }
      });
    }

    // Filter out tests from xsAudienceCookies Exclusions if test no longer active
    if (typeof xsAudienceExclusionCookies === 'string') {
      const xsAudienceCookiesArray = xsAudienceExclusionCookies.split('.');
      const xsAudienceCookieSplice = _.clone(xsAudienceCookiesArray);

      xsAudienceCookiesArray?.forEach((key: string) => {
        // Removes "--##" substring from checked keys (if substring exists) to retain cookies for content-based query check
        if (!validKey.includes(key.replace(/(--([0-9]+))/g, ''))) {
          const xsAudienceCookieIndex = xsAudienceCookieSplice.indexOf(key);
          xsAudienceCookieSplice.splice(xsAudienceCookieIndex, 1);
          if (xsAudienceCookieSplice.length === 0) {
            document.cookie = `xsAudienceExclusionCookies=;expires=${epochStartGmt};`;
            document.cookie = `xsAudienceExclusionCookies=;expires=${epochStartGmt};path=/;`;
          } else {
            document.cookie = `xsAudienceExclusionCookies=${xsAudienceCookieSplice
              .toString()
              .replace(',', '.')};path=/;`;
          }
        }
      });
    }

    if (!isDisableXS(url.search)) {
      // get the experiments preview header
      experimentsPreviewHeader = getExperimentsPreviewHeader(
        experimentsHeader,
        normalizedExperiments,
        url.search,
        cookies,
      );

      contextExperimentsHeader =
        experimentsPreviewHeader || experimentsHeader || '';
    }
  }

  return (
    <ExperimentContext.Provider
      value={{
        experiments,
        bucketedExperiments: filterExperiments(
          experiments,
          contextExperimentsHeader,
          country,
        ),
        experimentsHeader: contextExperimentsHeader,
        tests: mergedTests,
        cookies: cookies !== undefined ? cookies : '',
      }}
    >
      <XSProvider
        user={{
          visitorId: typeof visitorId === 'string' ? visitorId : '',
          locale: MarketUtility.getXSLocale(country, language),
        }}
        baseUrl={wwUtility.getAPIDomain()}
        isServerSide={ssr}
        plugins={[gua]}
      >
        {children}
      </XSProvider>
    </ExperimentContext.Provider>
  );
};
