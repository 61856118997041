import {
  NormalizedAttributes,
  NormalizedExperiments,
  NormalizedVariations,
} from '@ww-digital/xs-sdk';
import React from 'react';

import type { TestConfig } from '../ww.tests';
import { wwTests } from '../ww.tests.ts';

export type FormattedExperimentType = {
  experimentName: string;
  experimentTextId: string;
  variations: NormalizedVariations;
};

export type FormattedExperiments = {
  [experimentId: number]: FormattedExperimentType;
};
export type BucketedExperimentType = {
  experimentTextId: string;
  experimentName: string;
  attributes: NormalizedAttributes;
};
export type ExperimentContextType = {
  experiments: NormalizedExperiments;
  bucketedExperiments: {
    [experimentName: string]: BucketedExperimentType;
  };
  experimentsHeader: string;
  tests: TestConfig;
  cookies: string;
};

export const defaultExperimentContext: ExperimentContextType = {
  experiments: {},
  bucketedExperiments: {},
  experimentsHeader: '',
  tests: wwTests,
  cookies: '',
};

export const ExperimentContext = React.createContext<ExperimentContextType>(
  defaultExperimentContext,
);
