import React, { useContext } from 'react';

import type { MarketContextType } from '../../../context/market.context';

import { MarketContext } from '../../../context/market.context.ts';

import { ToutSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ToutSlice/ToutSlice';

import styles from './MaintenancePage.module.scss';

const translation: Record<
  MarketContextType['country'],
  Record<
    string,
    {
      title: string;
      text: string;
      footer: string;
    }
  >
> = {
  au: {
    en: {
      title: "We'll be right back",
      text: "Our site is currently down for important maintenance, but we'll be up as soon as possible.",
      footer: 'Thank you for your patience.',
    },
  },
  be: {
    nl: {
      title: 'We zijn zo terug',
      text: 'Op dit moment is onze website offline voor belangrijk onderhoud, maar we komen zo snel mogelijk weer online.',
      footer: 'Bedankt voor je geduld.',
    },
    fr: {
      title: 'Nous sommes bientôt de retour',
      text: 'En ce moment, notre site web est hors ligne en raison d’une maintenance importante, mais nous revenons en ligne aussi vite que possible.',
      footer: 'Merci pour votre patience.',
    },
  },
  br: {
    pt: {
      title: 'Voltamos em breve',
      text: 'Nosso site está desativado para algumas manutenções importantes, mas estaremos prontos assim que possível.',
      footer: 'Obrigado por sua paciência.',
    },
  },
  ca: {
    en: {
      title: "We'll be right back",
      text: "Our site is currently down for important maintenance, but we'll be up as soon as possible.",
      footer: 'Thank you for your patience.',
    },
    fr: {
      title: 'Nous serons de retour en un rien de temps',
      text: 'Notre site est présentement indisponible en raison de travaux de maintenance importants, mais il sera de nouveau en ligne dans les meilleurs délais.',
      footer: 'Merci de votre patience.',
    },
  },
  ch: {
    de: {
      title: 'Wir sind gleich wieder für dich da',
      text: 'Unsere Website ist aktuell wegen wichtiger Wartungsarbeiten nicht erreichbar, wir sind aber schnellstmöglich wieder für dich da sein.',
      footer: 'Vielen Dank für deine Geduld!',
    },
    fr: {
      title: 'Nous serons de retour en un rien de temps',
      text: 'Notre site est présentement indisponible en raison de travaux de maintenance importants, mais il sera de nouveau en ligne dans les meilleurs délais.',
      footer: 'Merci de votre patience.',
    },
  },
  de: {
    de: {
      title: 'Wir sind gleich wieder für dich da',
      text: 'Unsere Website ist aktuell wegen wichtiger Wartungsarbeiten nicht erreichbar. Wir sind schnellstmöglich wieder für dich da.',
      footer: 'Vielen Dank für deine Geduld!',
    },
  },
  fr: {
    fr: {
      title: 'Nous serons bientôt de retour',
      text: "Notre site est actuellement inaccessible en raison d'une importante opération de maintenance, mais il sera à nouveau disponible dès que possible.",
      footer: 'Merci de votre patience.',
    },
  },
  nl: {
    nl: {
      title: 'We zijn zo terug',
      text: 'Op dit moment is onze website offline voor belangrijk onderhoud, maar we komen zo snel mogelijk weer online.',
      footer: 'Bedankt voor je geduld.',
    },
  },
  nz: {
    en: {
      title: "We'll be right back",
      text: "Our site is currently down for important maintenance, but we'll be up as soon as possible.",
      footer: 'Thank you for your patience.',
    },
  },
  se: {
    sv: {
      title: 'Vi är snart tillbaka',
      text: 'Vår webbplats är tillfälligt stängd för underhåll och öppnar så fort det är möjligt.',
      footer: 'Tack för ditt tålamod!',
    },
  },
  uk: {
    en: {
      title: "We'll be right back",
      text: "Our site is currently down for important maintenance, but we'll be up as soon as possible.",
      footer: 'Thank you for your patience.',
    },
  },
  us: {
    en: {
      title: "We'll be right back",
      text: "Our site is currently down for important maintenance, but we'll be up as soon as possible.",
      footer: 'Thank you for your patience.',
    },
  },
};

const background = {
  color: '#322DA0',
  height: 300,
  theme: 'darkBackground',
  mobile: {
    gradient: {
      color: null,
      height: null,
      type: null,
    },
    image: {
      image1x: null,
      image2x: null,
      position: null,
      padding: null,
    },
  },
  desktop: {
    gradient: {
      color: null,
      height: null,
      type: null,
    },
    image: {
      image1x: null,
      image2x: null,
      position: {
        x: null,
        y: null,
      },
    },
  },
};

export const MaintenancePage = (): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);

  const texts = translation[country][language];

  const tout = {
    align: 'center',
    heading: {
      text: texts.title,
      alignment: 'center',
      typog: 'headline2',
      variant: 'accent',
      ariaLevel: 1,
    },
    bodyText: texts.text,
    button: null,
    footnoteText: texts.footer,
    icon: {
      source: {
        url: null,
        xml: '<svg width="75px" height="75px" viewBox="0 0 75 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n<g id="maintenance-logo" fill="#FFFFFF" fill-rule="nonzero">\n<path d="M75,37.5 C75,16.79 58.21,0 37.5,0 C16.79,0 0,16.79 0,37.5 C0,58.21 16.79,75 37.5,75 C58.21,75 75,58.21 75,37.5 Z M48.701,38.93 L56.091,38.93 L48.701,63.93 L40.972,63.93 L37.5,48.564 L37.403,48.564 L33.93,63.93 L26.298,63.93 L18.908,38.93 L26.298,38.93 L18.908,13.93 L26.756,13.93 L30.332,30.09 L30.43,30.09 L34.152,13.93 L40.846,13.93 L44.568,30.09 L44.666,30.09 L48.242,13.93 L56.089,13.93 L48.7,38.93 L48.701,38.93 Z M44.667,55.083 L48.243,38.931 L40.973,38.931 L37.5,23.563 L37.403,23.563 L34.028,38.93 L26.757,38.93 L30.333,55.082 L30.431,55.082 L34.153,38.93 L40.847,38.93 L44.569,55.082 L44.667,55.082 L44.667,55.083 Z" id="a"></path>\n</g>\n</g>\n</svg>',
      },
      height: '100px',
      width: '100px',
    },
    height: 300,
    positionHoriz: 'center',
    positionVert: 'center',
  };

  return (
    <div className={styles.maintenance}>
      <ToutSlice tout={tout} background={background} />
    </div>
  );
};
