import { useContext } from 'react';

import type { ExperimentContextType } from '../context/experiment.context';
import type { NormalizedAttributes } from '@ww-digital/xs-sdk';
import { SliceContainerProps } from '../components/Slices/SliceContainer/SliceContainer';

import { ExperimentContext } from '../context/experiment.context.ts';
import { JSONSafeParse } from '../components/Utility/xsUtility/JSONSafeParse.ts';
import { pauseExperiment } from '../components/Utility/xsUtility/pauseExperiment.ts';
import { MarketContext, MarketContextType } from '../context/market.context.ts';

/* Types */
export interface ExperimentsType {
  [experimentTextId: string]: {
    experimentTextId: string;
    experimentName: string;
    attributes: {
      [attributeName: string]: AttributeType | any;
    };
    configuration?: AttributeType['configuration'];
    forceExposure?: AttributeType['forceExposure'];
    path?: AttributeType['path'];
  };
}

export interface AttributeType {
  path?: {
    id: string;
    name: string;
    data: string;
  };
  configuration?: {
    audience?: {
      excludeFranchise?: boolean;
      zipCodes?: {
        franchise?: string[];
        naco?: string[];
      };
    };
    globalCalendar?: GlobalCalendarType;
    regions?: ['header' | 'footer' | 'body'];
  };
  forceExposure?: boolean;
  attributes: {
    [attributeName: string]: TemplatesAttributeType | any;
  };
}

export interface GlobalCalendarType {
  timezone: string;
  dateFormat: string;
  dates?:
    | [
        {
          startDate: string;
          endDate: string;
          pause: boolean;
        },
      ]
    | [];
}

export interface TemplatesAttributeType {
  audience?: {
    excludeFranchise?: boolean;
  };
  config: {
    region?: 'header' | 'footer' | 'body';
    pageName?: string;
    sliceSelector: {
      id: string;
      __typename: string;
      targetBy?: 'id' | '__typename';
    };
  };
  alterMethod: 'update' | 'replace' | 'remove' | 'inject';
  alterProps: SliceContainerProps['slice'] | null;
  injectionIndex?: number;
  dates?: [
    {
      startDate: string;
      endDate: string;
      alterProps: SliceContainerProps['slice'] | null;
    },
  ];
  cssChanges?: string;
}

/* Helper Functions */
const mapAttributes = (rawAttributes: NormalizedAttributes): AttributeType => {
  const mappedAttributes: AttributeType = {
    attributes: {},
  };
  for (const attribute in rawAttributes) {
    const parsedAttributeValue: AttributeType | any = JSONSafeParse(
      rawAttributes[attribute].attributeValue,
    );
    // if the attributeValue is not a valid JSON, skip it
    if (parsedAttributeValue.error) {
      continue;
    }
    if (parsedAttributeValue.description) {
      delete parsedAttributeValue.description;
    }
    if (
      attribute === 'configuration' ||
      attribute === 'path' ||
      attribute === 'forceExposure'
    ) {
      mappedAttributes[attribute] = parsedAttributeValue;
    } else if (attribute !== 'autoRoute') {
      mappedAttributes.attributes[attribute] = parsedAttributeValue;
    }
  }
  return mappedAttributes;
};

/* useExperiments Hook */
export const useExperiments = (): Record<'experiments', ExperimentsType> => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const { bucketedExperiments } =
    useContext<ExperimentContextType>(ExperimentContext);
  const experiments: ExperimentsType = {};
  const country = marketContext.country;
  // parse the attributes values in the bucketedExperiments
  for (const experimentTextId in bucketedExperiments) {
    const experiment = bucketedExperiments[experimentTextId];
    const { attributes } = experiment;
    const parsedAttributes = mapAttributes(attributes);

    // filter timezones and date formats for multi-market experiments
    if (parsedAttributes.configuration?.globalCalendar) {
      const timezone = parsedAttributes.configuration.globalCalendar.timezone;
      const dateFormat =
        parsedAttributes.configuration.globalCalendar.dateFormat;

      parsedAttributes.configuration.globalCalendar.timezone =
        typeof timezone === 'string' ? timezone : timezone[country];

      parsedAttributes.configuration.globalCalendar.dateFormat =
        typeof dateFormat === 'string' ? dateFormat : dateFormat[country];
    }
    // filter out paused experiments
    if (
      parsedAttributes.configuration?.globalCalendar?.dates &&
      parsedAttributes.configuration.globalCalendar.dates.length &&
      pauseExperiment(
        parsedAttributes.configuration.globalCalendar.dates,
        parsedAttributes.configuration.globalCalendar.timezone,
        parsedAttributes.configuration.globalCalendar.dateFormat,
      )
    ) {
      continue;
    }
    // add the parsed attributes to the experiments object
    experiments[experiment.experimentTextId] = {
      experimentTextId: experiment.experimentTextId,
      experimentName: experiment.experimentName,
      ...parsedAttributes,
    };
  }
  return { experiments };
};
