import { MarketUtility } from './MarketUtility.ts';

export class PricingUtility {
  static countriesFallbackZip: Record<string, string> = {
    fr: '78400',
    us: '99999',
    // @TODO need fallback for future markets
  };

  static countriesZipcodeRegex: Record<string, string> = {
    au: '\\d{4}',
    be: '\\d{4}',
    br: '\\d{5}[-]?\\d{3}',
    ca: '[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d',
    ch: '\\d{4}',
    de: '\\d{5}',
    fr: '\\d{2}[ ]?\\d{3}',
    nl: '\\d{4}[ ]?[A-Z]{2}',
    nz: '\\d{4}',
    se: '\\d{3}[ ]?\\d{2}',
    uk: 'GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1,4}',
    us: '\\d{5}([ -]\\d{4})?',
  };

  // Price tokens mapped to the Commerce API data fields
  static priceTokens: Record<string, string> = {
    '[price:token]': 'price',
    '[retail:token]': 'retailPrice',
    '[averageSalePrice:token]': 'averageSalePrice',
    '[averageRetailPrice:token]': 'averageRetailPrice',
  };

  // Helper function to indicate if text has specified token
  static hasToken(field: string, text?: string) {
    const tokenField = field === 'retailPrice' ? 'retail' : field;
    return (
      text &&
      typeof text === 'string' &&
      text.indexOf(`[${tokenField}:token]`) >= 0
    );
  }

  // Helper function to indicate if text has price token
  static hasPricingToken(text?: string) {
    if (!text) {
      return false;
    }

    return Object.values(PricingUtility.priceTokens).some((token) => {
      return PricingUtility.hasToken(token, text);
    });
  }

  static fallbackZip(country: string) {
    return PricingUtility.countriesFallbackZip[country] || '';
  }

  static zipcodeRegExp(country: string) {
    const pattern = PricingUtility.countriesZipcodeRegex[country] || '.+';
    return new RegExp(`^(${pattern})$`, 'i');
  }

  static getRecurlyPricingUrl(country: string, language = ''): string {
    const marketInfo = MarketUtility.getMarketInfo(country, language);
    return marketInfo ? `${marketInfo.marketBasePath}/${marketInfo.plans}` : '';
  }
}
